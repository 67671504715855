// force only lodash 3 in the global scope
//
const _3 = window._;

// change how we interpolate templates
//
_3.templateSettings = {
  interpolate: /{{([\s\S]+?)}}/g
};

// do not let anyone change this
//
const __3 = Object.freeze(_3);

// protect lodash 3 from being hacked
//
//
Object.defineProperty(window, '_', {
  configurable: false,
  get() {
    return __3;
  },
  set() {
    // never allow set
  }
});
