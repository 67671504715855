angular
  .module('app', [
    'ngSanitize',
    'ngAria',
    'ngMessages',
    'ngAnimate',
    'capability-ng-transfer',
    'capability-ng-checklist',
    'capability-ng-resources',
    'capability-ng-sitesetup',
    'capability-ng-tda-constants',
    'capability-ng-anonymous-landing',
    'capability-ng-user-app',
    'capability-ng-global',
    'vendor',
    'capability-ng-admin-authentication',
    'ct.ui.router.extras.core',
    'ct.ui.router.extras.dsr',
    'ct.ui.router.extras.sticky'
  ])
  .config(function(ConfigurationProvider) {
    ConfigurationProvider.write({
      about: {
        application: 'Transfer',
        subheader: 'An EAB Application',
        version: {
          ui: '0.2.0',
          api: '1'
        }
      }
    });
  })
  .config(function (FaultToleranceConfigurationProvider) {
    FaultToleranceConfigurationProvider.activate({
      captureErrors: false
    });
  })
  .config(function (Interface) {
    Interface.configure({
      verbose: true,
      defaultToRequired: false,
      failFast: false
    });
  })
  .config(function improveBusinessDecision() {
    const visitor = {};
    const account = {
      id: location.hostname,
      product: 'Transfer',
      environment: 'Production',
      device: 'Desktop'
    };

    if ('aptrinsic' in window && _.isFunction(window['aptrinsic'])) {
      aptrinsic('identify', visitor, account);
    }
  });

const bypassEmailTypeValidation = () => {
  (window).HIP.aop.pointCut('hi-input', 'checkValidity', function(fn) {
    return function(...args) {
      const isEmailField = this.getAttribute('type') === 'email';
      const hasEmailErrorDetected = this.validity.typeMismatch;
      const letContinueOtherValidations = !isEmailField || !hasEmailErrorDetected;
      if (letContinueOtherValidations) {
        return fn.call(this, args);
      }
      // clearing the email field related validations
      ['invalid', 'dirty'].forEach((attribute) => this.removeAttribute(attribute));
      this.__validationNoticeEl.validationNotice = '';
      return true;
    };
  });
};

angular.element(document).ready(function() {
  document.addEventListener('hi-ready', function() {
    angular.bootstrap(document, ['app']);
    bypassEmailTypeValidation();
  });
  if ((window).HTMLImports && (window).HTMLImports.useNative) {
    document.dispatchEvent(new CustomEvent('WebComponentsReady', { bubbles: true }));
  }
});

document.addEventListener('hi-ready', function () {
  window['$ip'].constants.upgrade({
    '@server': `${new URL(window.location.toString()).origin}/api`
  });
});
